<template>
  <TsCard
    id="trolley-preview"
    :data-testid="props.dataTestid"
    :append-class="
      twMerge(
        'max-h-[inherit] flex flex-col overflow-auto px-3 py-4 bg-white',
        isMobile && 'rounded-b-none'
      )
    "
  >
    <div class="flex items-center justify-between mb-2.5">
      <TsRow append-class="m-0 pr-7">
        <TsTypography
          v-if="trolleyStore.previewed_product?.full_name"
          append-class="mb-0 text-idle-black"
        >
          <span class="font-bold">{{
            trolleyStore.previewed_product?.full_name.split(" ")[0]
          }}</span>
          {{
            trolleyStore.previewed_product?.full_name
              ?.split(" ")
              .slice(1)
              .join(" ")
          }}
        </TsTypography>
        <TsTypography v-else append-class="mb-0 text-idle-black">
          Lorem ipsum dolor sit amet consectetur adipisicing
        </TsTypography>
      </TsRow>
      <TsButton
        class="hover:bg-info/10 rounded max-w-max"
        icon="mdi:close"
        size="lg"
        @click="emit('close')"
      >
      </TsButton>
    </div>

    <!-- <template>
      <TsTypography as="h4"> Choose a Colour </TsTypography>

      <div class="flex items-center gap-2">
        <div>
          <div class="p-2 mb-2 border-2 border-primary rounded-xl">
            <NuxtImg src="/images/shoes-1.png" width="48" height="48" />
          </div>

          <TsTypography size="xs" align="center" append-class="text-primary">
            Black
          </TsTypography>
        </div>
        <div>
          <div class="p-2 mb-2 border border-natural-light-grey rounded-xl">
            <NuxtImg src="/images/shoes-1.png" width="48" height="48" />
          </div>

          <TsTypography
            size="xs"
            align="center"
            append-class="text-natural-black"
          >
            Brown
          </TsTypography>
        </div>
        <div>
          <div
            class="overflow-hidden p-2 mb-2 border border-natural-light-grey rounded-xl relative after:content-[''] after:w-28 after:rotate-45 after:border after:border-natural-light-grey after:absolute after:top-1/2 after:left-1/2 after:-translate-y-1/2 after:-translate-x-1/2"
          >
            <NuxtImg
              class="opacity-40"
              src="/images/shoes-1.png"
              width="48"
              height="48"
            />
          </div>

          <TsTypography
            size="xs"
            align="center"
            append-class="text-natural-black/50"
          >
            Honey
          </TsTypography>
        </div>
      </div>

      <TsDivider />

      <TsTypography variant="h2" append-class="mb-0 leading-none"
        >Size: 40</TsTypography
      >

      <TsTypography append-class="text-natural-black mb-4" weight="medium">
        Garment Measurement: <span class="font-normal">Chest 40.0in</span>
      </TsTypography>

      <div class="flex items-center justify-between mb-4">
        <TsTag
          data-testid=""
          variant="info"
          label="Size 40 recommended"
          append-class="outline-0 border-0 py-[3px]"
          size="sm"
          outlined
        />

        <TsButton
          label="Size chart"
          icon="uil:angle-right"
          icon-position="right"
          icon-class="size-6"
          append-class="border-0 p-0 gap-0"
          outlined
        />
      </div>

      <div class="flex items-center gap-2 mb-2">
        <div
          class="h-12 w-12 relative border border-natural-light-grey rounded-xl"
        >
          <span
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >36</span
          >
        </div>

        <div
          class="h-12 w-12 relative border-2 border-[#094983] bg-primary text-idle-white rounded-xl"
        >
          <span
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >36</span
          >
        </div>

        <div
          class="h-12 w-12 relative border border-natural-light-grey rounded-xl"
        >
          <span
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >36</span
          >
        </div>

        <div
          class="h-12 w-12 relative border overflow-hidden border-natural-light-grey bg-natural-soft-white text-natural-light-grey rounded-xl after:content-[''] after:w-16 after:rotate-45 after:border after:border-natural-light-grey after:absolute after:top-1/2 after:left-1/2 after:-translate-y-1/2 after:-translate-x-1/2"
        >
          <span
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >36</span
          >
        </div>
      </div>

      <TsTypography append-class="text-[#E52228] mb-4" size="xs">
        10 left
      </TsTypography>
    </template> -->

    <template v-if="trolleyStore.previewed_product?.variations?.length">
      <TsTypography as="h4">
        Select Product Variants ({{
          trolleyStore.previewed_product?.variations?.length
        }})
      </TsTypography>

      <TsButton
        :label="selectedVariantLabel"
        variant="secondary"
        icon="pepicons-pop:angle-down"
        iconPosition="right"
        append-class="mb-4 bg-white justify-between"
        iconClass="text-natural-silver-grey"
        @click="emit('clickVariants')"
        outlined
      />
    </template>

    <template v-if="props.loading">
      <TsBranchTransition v-for="n in 2" :key="n" />
    </template>

    <template v-else>
      <TsMedia>
        <TsMediaStart append-class="p-4">
          <TsQuantityCounter
            v-model="trolleyStore.previewed_product_selected_quantity"
            :disabled="!selectedChannelStockTag"
            data-testid="trolley-preview-quantity-counter"
            @increment="
              emit(
                'increment',
                trolleyStore.previewed_product_selected_quantity
              )
            "
            @decrement="
              emit(
                'decrement',
                trolleyStore.previewed_product_selected_quantity
              )
            "
            @change="
              emit('change', trolleyStore.previewed_product_selected_quantity)
            "
            @input="
              emit('input', trolleyStore.previewed_product_selected_quantity)
            "
          />

          <TsTag
            v-if="selectedChannelStockTag"
            :label="selectedChannelStockTag"
            data-testid="selected-channel-stock"
            variant="info"
            rounded
            size="sm"
            append-class="mt-3 px-6"
          />
        </TsMediaStart>

        <TsMediaContent append-class="pl-4">
          <div class="flex">
            <TsTypography
              size="sm"
              v-if="
                trolleyStore.previewed_product?.prices?.was_price_formatted ??
                0 > 0
              "
              append-class="line-through leading-tight mr-1"
            >
              {{
                `${trolleyStore.previewed_product?.prices?.was_price_formatted}`
              }}
            </TsTypography>

            <TsTypography
              v-if="
                trolleyStore.previewed_product?.prices?.formatted
                  .discount_percentage !== '0%'
              "
              size="sm"
              weight="bold"
              append-class="text-success leading-tight"
            >
              ({{
                trolleyStore.previewed_product?.prices?.formatted
                  .discount_percentage
              }}
              OFF)
            </TsTypography>
          </div>

          <TsTypography
            variant="h2"
            weight="bold"
            append-class="text-idle-black leading-normal mb-0 leading-tight"
          >
            {{
              rootStore.isIncVat
                ? trolleyStore.previewed_product?.prices?.formatted.gross
                : trolleyStore.previewed_product?.prices?.formatted.net
            }}
            <TsTypography
              v-if="trolleyStore.previewed_product?.prices?.formatted.per_unit"
              as="span"
              variant="xs"
              append-class="text-natural-silver-grey leading-tight"
            >
              {{ trolleyStore.previewed_product?.prices?.formatted.per_unit }}
            </TsTypography>
          </TsTypography>

          <TsTypography as="span" variant="xs" append-class="leading-tight">
            {{ rootStore.isIncVat ? "Excl." : "Inc." }} VAT
            {{
              rootStore.isIncVat
                ? trolleyStore.previewed_product?.prices?.formatted.net
                : trolleyStore.previewed_product?.prices?.formatted.gross
            }}
          </TsTypography>
        </TsMediaContent>
      </TsMedia>

      <TsDivider />

      <TsTypography as="h4"> How to Get it Delivered </TsTypography>

      <div class="md:flex md:gap-2">
        <!-- Add to trolley delivery channel -->
        <TsRadioTrolleyDelivery
          v-model="trolleyStore.previewed_product_selected_channel"
          :value="TrolleyChannel.Delivery"
          :disabled="!deliveryStockTag"
          checked
          :delivery-stock="deliveryStockTag"
          name="channel_option"
          data-testid="delivery_option"
          append-class="w-full p-3 gap-3 mb-2 max-w-1/2 cursor-pointer"
          input-class="size-5 m-1"
          label-class="p-0"
          outlined
        />

        <!-- Add to trolley collection channel -->
        <TsRadioTrolleyCollection
          v-if="
            trolleyStore.previewed_product?.click_and_collect &&
            !trolleyStore.previewed_product.direct_ship
          "
          v-model="trolleyStore.previewed_product_selected_channel"
          :value="TrolleyChannel.Collection"
          :availability="collectionAvailability"
          :branch-name="branchStore.lastSavedBranch?.name"
          @set-branch="emit('setBranch')"
          :loading="trolleyStore.collection_stock_loading"
          name="channel_option"
          data-testid="collection_option"
          append-class="w-full p-3 gap-3 mb-2 cursor-pointer"
          input-class="size-5 m-1 cursor-default"
          label-class="p-0 cursor-default"
          outlined
          :disabled="!branchStore.is_branch_set || !collectionStockTag"
        />
      </div>

      <slot name="cta">
        <TsButton
          v-if="mode === 'checkout'"
          icon="fa:angle-double-right"
          label="Buy now"
          append-class="mt-4 gap-1.5 mx-auto max-w-sm"
          size="sm"
          block
          @click="
            emit(
              'buyNow',
              trolleyStore.previewed_product_selected_quantity,
              trolleyStore.previewed_product_selected_channel
            )
          "
        />

        <TsButton
          v-if="mode === 'trolley'"
          icon="bx:cart"
          :label="trolleyStore.is_added ? 'Added to trolley' : 'Add to trolley'"
          :is-loading="trolleyStore.loading"
          :append-class="
            twJoin(
              'mt-4 gap-1.5 mx-auto max-w-sm',
              trolleyStore.is_added &&
                'bg-success hover:bg-success-hover border-success text-idle-white'
            )
          "
          size="sm"
          block
          @click="
            emit(
              'addToTrolley',
              trolleyStore.previewed_product_selected_quantity
            )
          "
          :disabled="isAddToTrolleyButtonDisabled"
        />
      </slot>
    </template>
  </TsCard>
</template>

<script setup lang="ts">
const rootStore = useRootStore();
import { twJoin, twMerge } from "tailwind-merge";
import type { ProductAvailability } from "../atoms/TsRadioTrolleyCollection.vue";
import TsTypography from "../atoms/TsTypography.vue";
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";

type Props = {
  dataTestid?: string;
  mode?: "trolley" | "checkout";
  loading?: boolean;
  selectedVariantLabel?: string;
  collectionAvailability?: ProductAvailability;
};

const props = withDefaults(defineProps<Props>(), {
  mode: "trolley",
  collectionAvailability: () => ({ status: "NoBranchSelected", stock: 0 }),
  selectedVariantLabel: "3x61 mm",
});

const emit = defineEmits<{
  addToTrolley: [q: number];
  buyNow: [q: number, c: number];
  setBranch: any;
  close: any;
  clickVariants: any;
  increment: [q: number];
  decrement: [q: number];
  change: [q: number];
  input: [q: number];
}>();

const trolleyStore = useTrolleyStore();
const branchStore = useBranchStore();

const deliveryStockTag = computed<string>(() => {
  const stockDetails = trolleyStore.previewed_product?.stockDetails;
  const selectedQuantity = trolleyStore.previewed_product_selected_quantity;
  const selectedChannel = trolleyStore.previewed_product_selected_channel;

  if (!stockDetails?.delivery) return "";

  const availableDeliveryStock = stockDetails.delivery;

  // if chosen quantity exceeds available
  if (selectedChannel === TrolleyChannel.Delivery && selectedQuantity > availableDeliveryStock) {
    return "";
  }

  return (availableDeliveryStock <= 20 ? availableDeliveryStock.toString() : "20+") + " available";
});


const collectionStockTag = computed<string>(() => {
  if (!props.collectionAvailability.stock) return "";
  return (
    (props.collectionAvailability.stock <= 20
      ? props.collectionAvailability.stock
      : "20+") + " available"
  );
});

const selectedChannelStockTag = computed<string>(() =>
  trolleyStore.previewed_product_selected_channel === 1
    ? deliveryStockTag.value
    : collectionStockTag.value
);

const isAddToTrolleyButtonDisabled = computed(() => {
  const selectedQuantity = trolleyStore.previewed_product_selected_quantity;
  const selectedChannel = trolleyStore.previewed_product_selected_channel;
  const product = trolleyStore.previewed_product;
  const stockDetails = product?.stockDetails;

  const isGreaterThanDeliveryStock = selectedQuantity > stockDetails?.delivery!;
  const isGreaterThanCollectionStock =
    selectedQuantity > stockDetails?.collection!;

  return (
    trolleyStore.is_added ||
    !selectedChannelStockTag ||
    !selectedQuantity ||
    (selectedChannel === TrolleyChannel.Delivery
      ? isGreaterThanDeliveryStock
      : props.collectionAvailability.status === "NextDayCollection"
      ? isGreaterThanDeliveryStock
      : isGreaterThanCollectionStock)
  );
});

const { isMobile } = useDevice();

// reset the buttons if either the quantity or the channel changes
const unwatch = watch(
  () => trolleyStore.previewed_product_selected_channel,
  (newChannel) => {
    trolleyStore.is_added = false;
  }
);
</script>
